import React from "react"
import _ from "lodash"
import I18n from "i18n-js"

import { useBet } from "@root/bet_utils/context/bet"
import { textPickPresenter } from "./presenter"
import betStatusPresenter from "@root/bet_utils/presenter/bet_status"
import postponedPresenter from "@root/bet_utils/presenter/postponed"
import { prettyTimeLeft } from "@root/bet_utils/use_deadline_countdown"

const sportIconContext = require.context("@images", true, /sport--.*\.png/)
const sportIcon = (sport: string): string | null => {
  const key = `./sport--${sport}.png`
  return _.includes(sportIconContext.keys(), key) ? sportIconContext(key) : null
}

export const Wrapper: React.FC = ({ children }) => <div className="textSelectionBet__wrapper">{children}</div>
export const Body: React.FC = ({ children }) => <div className="textSelectionBet__body">{children}</div>
export const Footer: React.FC = ({ children }) => <div className="textSelectionBet__footer">{children}</div>

export const HeaderMeta: React.FC<{bet: TextSelection.Api.Bet}> = ({ bet }) => {
  if (postponedPresenter.isPostponed(bet)) {
    return <span>{I18n.t("postponed.status")}</span>
  } else if (betStatusPresenter.isMatchOpen(bet)) {
    return <span>{prettyTimeLeft(bet.deadline)}</span>
  } else if (betStatusPresenter.questionWaitingForScoring(bet)) {
    return <span>{I18n.t("bet.in_progress")}</span>
  } else if (betStatusPresenter.isMatchOverAndScored(bet)) {
    const gainedPoints = bet.total_score || 0
    return <span className={`textSelectionBet__header__betMarker textSelectionBet__header__betMarker--${gainedPoints > 0 ? "correct" : "wrong"}`} />
  }
  return null
}

export const Header: React.FC = () => {
  const bet = useBet() as TextSelection.Api.Bet
  const iconSrc = sportIcon(textPickPresenter.sport(bet)) || sportIcon("default") || ""

  return <div className="textSelectionBet__header">
    <div className="textSelectionBet__headerMeta"><HeaderMeta bet={bet} /></div>
    {bet.toyota_question && <div className="textSelectionBet__dailySpecialMarker"><span /></div>}
    <div className="textSelectionBet__headerIcon"><img src={iconSrc} /></div>
  </div>
}
