import React, { useContext } from 'react'
import I18n from 'i18n-js'

import BetContext from './context/bet'
import postponedPresenter from './presenter/postponed'

const PostponedInfo = ({ i18nNamespace = 'postponed', cssNamespace = 'postponedInfo', bet }) => {
  bet || (bet = useContext(BetContext))

  const babelPath = (key) => { return `${i18nNamespace}.${key}` }
  const cssClass = (cls) => { return `${cssNamespace}__${cls}` }

  return postponedPresenter.isPostponed(bet) && (
    <div className={cssClass('wrapper')}>
      {postponedPresenter.hasDate(bet)
        ? I18n.t(babelPath('info_with_date'), { date: postponedPresenter.getPrettyDate(bet) })
        : I18n.t(babelPath('info'))}
      {postponedPresenter.hasUrl(bet) &&
        <React.Fragment>
          {' ' /* space-character */}
          <a href={postponedPresenter.getUrl(bet)}>{I18n.t(babelPath('link'), { url: postponedPresenter.getUrl(bet) })}</a>
        </React.Fragment>}
    </div>
  )
}

export default PostponedInfo
